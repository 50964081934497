class Store {
    constructor() {
      this.state = {
        profile: [],
     
      };
    }
    setState(db) {
      this.state.profile = db;
    }
    getState() {
      return this.state.profile;
    }  

  }
  
  const store = new Store({});
  export default store;