import React from "react";
import { Route, Switch,Redirect } from 'react-router-dom';
import APP from "./App";
import Portfolio from "./view/Portfolio";
import Profile from "./view/Profile";
import TrackDelivery from "./view/TrackDelivery";
import Shop from "./view/Shop";
import ContactUs from "./view/ContactUs";
import ProductDetails from "./view/ProductDetail";
// import Login from "./view/login";
// import AdminDashboard from "./view/AdminDashboard";
import MainFooter from "./components/Footer";


export const Routes = () => {



  return (
  
    <div className="mainContainer">
   
      
    
        <div className="routeBlock" >
           <Switch>
           <Route exact path="/"> <Redirect to="/home" />  </Route>
            <Route exact path="/home" component={APP} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/shop" component={Shop} />
            <Route exact path="/trackdelivery" component={TrackDelivery} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/productdetails/:id" component={ProductDetails} />
            {/* <Route exact path="/login" component={Login} /> */}
            {/* <Route exact path="/adminDashboard" component={AdminDashboard} /> */}
          
          </Switch>
          <MainFooter />
        </div>
   
 
    </div>
   )
}
export default Routes;



