import axios from "axios";
import { Spinner } from 'react-bootstrap';
class Global {
     BaseURL(){
      // let url =(window.location.hostname === 'localhost')?"http://localhost/premierarts/php/":"http://premierartfxstudio.dollardreamsvillas.com/php/";
     let url = "https://premierartfxstudio.dollardreamsvillas.com/php/";
        return url
   }
   dataCall = (val, paramVal, cb) => {
        let filename = "startup.php"
        if(val === 'portfolio'){
          filename = "portfolio.php";
        }
        if(val === 'shop' || val === 'productdetail' || val === 'verifyUser' || val === 'removeBannerImage'){
          filename = "GetDB.php";
        }
       let contentType = (val === 'removeBannerImage')?'multipart/form-data':'application/x-www-form-urlencoded' ;
        
        //let filename = (val === 'bannerImage')?"startup.php":"recentWork.php"
        const article = {type:val, param:paramVal};
        const headers = { 'Content-Type': contentType};
        let url = this.BaseURL() + ""+filename;
        axios.post(url, article, headers)
          .then(response => {
             console.log("Global====",response)
             cb(response.data.result);
          })
          .catch(error=>{
            // cb(error) 
          })
    }
    WaitBlock=(text)=>{
     return (
      <div className="spinnerBlock text-center m-auto p-4">
      <Spinner animation="border" role="status">
         <span className="sr-only">Loading...</span>
      </Spinner>
      <p>{text}</p>
      </div>
     )
    }
    CurrencyConverter(val){
      let cc = (JSON.parse(val)).toLocaleString("hi", { style: "currency", currency: "INR", minimumFractionDigits: 2 });
      return cc
    }
    digitDiff(d1,d2){
      let result = JSON.parse(d1) - JSON.parse(d2);
      return this.CurrencyConverter(result);
    }
    changeDateFormat(date){
      // let nDate = date.split(" ");
      // console.log(nDate[0])
      // let currentDate = new Date(nDate[0]);
      // var fd = currentDate.toDateString();
      return date;
    }
}
export default new Global();