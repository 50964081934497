import "./OurServices.scss";
import iconFresh from "../assets/images/fresh2.png";
import iconcreative from "../assets/images/cidea.png";
import iconcustom from "../assets/images/custom.png";

let OurServices = () => {
    return (
        <div className="container ourServices">
            <div className="col-4 service-content">
                <div className="serviceIcon"><span>1</span>
                    <img src={iconFresh} alt="" />
                </div>
                <h4>Fresh Design</h4>
                {/* <p>There are many variations of passages of Lorem Ipsum available.</p> */}
            </div>
            <div className="col-4 service-content">
                <div className="serviceIcon"><span>2</span>
                    <img src={iconcreative} alt="" />
                </div>
                <h4>Creative Ideas</h4>
                {/* <p>But the majority have suffered alteration in some form, by injected humour.</p> */}
            </div>
            <div className="col-4 service-content">
                <div className="serviceIcon"><span>3</span>
                    <img src={iconcustom} alt="" className="customIcon" />
                </div>
                <h4>Easy Customisation</h4>
                {/* <p>A bunch of randomised words which don't look even slightly believable.</p> */}
            </div>
            {/* <div className="col-md-3 service-content">
                <div className="serviceIcon"><span>4</span><i className="icon-users"></i></div>
                <h4>Top Support</h4>
                <p>If you are going to use a passage of Lorem Ipsum,  you need to be sure.</p>
            </div> */}
        </div>
    )
}
export default OurServices;