import React,{useState,useEffect} from 'react';
import Banner from "../components/Banner"
import Global from "../components/Global"
import { NavLink } from 'react-router-dom';
let BaseURL = Global.BaseURL();


let getData = (setRecentWork) => {
  Global.dataCall('shop','', (data) => {
    localStorage.setItem('shop',JSON.stringify(data));
    setRecentWork(data);

  });
  //console.log(recentWorkArray)
}

let Shop = () => {
     const [recentWorkArray, setRecentWork] = useState(false);
    useEffect(() => {
      console.log('render!');
      if( localStorage.getItem('shop')){
        let getJsonData = JSON.parse(localStorage.getItem('shop'));
        setRecentWork(getJsonData)
      }
      getData(setRecentWork);
  
      return () => console.log('unmounting...');
    }, []);
  
    return (
        <div className=" shopWithUsContainer  no-slider mb-5">
            
            <Banner />
            <div className="container">
            <div className={'col-xs-12'}>
            <h3 className="head-title">Shop With Us.</h3>
            </div>
            <div col={12} className="productImageBlock">
            {!recentWorkArray && 
                <div className="submitForm">
                    {Global.WaitBlock("Please Wait...")}
                </div>
                }
            {recentWorkArray.userData && recentWorkArray.userData.map(val => (
                <div key={val.id} className={"productBlock col-xs-12"} >
                   <img src={BaseURL+"/shop/"+val.imagename} className="img-responsive" alt="img" />
                   <div className="priceTag">
                       <p><strong>Pre-Order:</strong> {val.preorderprice} INR</p>
                       <p><strong>Post-Pre-Order:</strong> {val.postpreprice} INR</p>
                       <p className="status" ><strong>Status:</strong><span className={val.status === 'Available'?'txtgreen':'txtgrey'}>{val.status}</span> </p>
                       <p><strong>No. of units:</strong> {val.availableunits} Available</p>
                       <button className="btn btn-primary redirectBtn"> 
            <NavLink to={"/productdetails/"+val.id} ><span >Buy Now</span> </NavLink></button>
                   </div>

                </div>
            ))}
           
            </div>
            </div>
            
        </div>
    )
}
export default Shop;