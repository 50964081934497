import Banner from "../components/Banner";
import React, { useEffect, useState } from 'react';
import {Image} from "react-bootstrap"
import Global from "../components/Global";
import JSModal from "../components/Modal";
let BaseURL = Global.BaseURL();

let getData = (setGoingUp) => {
    let obj = [];
  
    Global.dataCall('portfolio','', (data) => {
        for (var i = 0; i <= data.len; i++) {
            if (data.images[i]) {
                obj.push(BaseURL + "portfolio/" + data.images[i]);
            }
        }
        localStorage.setItem('portfolioImg', JSON.stringify(obj));
        if (obj) {
            setGoingUp(obj);
        }
      
    });
}
let Portfolio = () => {
    const [imagesArray, setGoingUp] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalImage, setModalImage] = React.useState(false);
    let setUseState =(animal)=>{
       
        setModalShow(true)
        setModalImage(animal)
    }
    useEffect(() => {
        console.log('render!');
        if( localStorage.getItem('portfolioImg')){
            let getImg  = JSON.parse(localStorage.getItem('portfolioImg'));
            if(getImg.length > 0){
                setGoingUp(getImg);
            }
        }
        getData(setGoingUp);
        return () => console.log('unmounting...');
    }, []);
    return (
        <div className="Portfolio no-slider mb-5">
            <Banner />
            <div className="container">

                <div className={'col-xs-12'}>
                    <h3 className="head-title">Portfolio</h3>
                </div>
                <div col={12} className="imageBlock">
                {!imagesArray && 
                <div className="submitForm">
                    {Global.WaitBlock("Please Wait...")}
                </div>
                }
                    {imagesArray && imagesArray.map(val => (
                        <div className="imageInner">
                            <div className={"portfolioImages "} >
                                <Image src={val} onClick={() => setUseState(val)} className="img-responsive" alt="img" thumbnail />
                                {/* <div className="overlay-wrap">
                                    <div className="overlay">
                                        <div className="overlay-inner">

                                            <div className="space30"></div>
                                            <h5>Project Title</h5>
                                            <p>Cum sociis natoque penatibus et magnis dis parturient montes.</p>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <JSModal className="imageModal" show={modalShow} onHide={() => setModalShow(false)} imgurl={modalImage}/>
        </div>
    )
}
export default Portfolio;