import React, { useState, useEffect } from 'react';
import Banner from "../components/Banner"
import Global from "../components/Global"
import GPAY from "../assets/images/gPay.png";
import { Form, Button,Alert} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import axios from "axios";
import JSModal from "../components/Modal"
let BaseURL = Global.BaseURL();

let getData = (setRecentWork, param) => {

    Global.dataCall('productdetail', param, (data) => {
        if (data && data.userData)
            setRecentWork(data.userData);

    });

}


let ProductDetail = (props) => {
    const [postDataResponse, postData] = useState(false);
    const [pDetail, setRecentWork] = useState(false);
    const [statusForm, submitForm] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalImage, setModalImage] = React.useState(false);
    useEffect(() => {
        const { match: { params } } = props;
        getData(setRecentWork, params.id);

        return () => console.log('unmounting...');
    }, []);
    let setUseState =(animal)=>{
       
        setModalShow(true)
        setModalImage(animal)
    }

    const submitPayment = (evt) => {
        submitForm(true);
        evt.preventDefault();
        const formData = new FormData(evt.target);
        var object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });
        console.log(object)
        const article = { data: object, type: "booking" };
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        // const Cors = { "mode": "no-cors" }
    
        axios.post(BaseURL + "postDB.php", article, headers)
            .then(response => {
                submitForm(false)
                if (response && response.data) {
                    console.log(response.data)
                    postData([response.data]);
                }
    
            
            })
            .catch(error => {
                submitForm(false)
                console.log("Error===", error)
            })
    }

    return (
        <div className="shopWithUsContainer  no-slider mb-5">
            <Banner />
            <div className="container">
                <div className={'col-xs-12'}>
                    <h3 className="head-title">Buy Now.</h3>
                </div>
                {!pDetail && 
                <div className="submitForm">
                    {Global.WaitBlock("Please Wait...")}
                </div>
                }
                 <div className="col-12 text-right mb-4"><NavLink to="/shop" ><span >Back to shop</span> </NavLink></div>
                {pDetail && pDetail.map(val => (
                    <div col={12} key={val.id} className="productImageBlock">
                       
                        <div className="col-xs-12 col-md-3 float-left productImage">
                            <img onClick={() => setUseState(val.imagename)} src={BaseURL + "shop/" + val.imagename} alt="" />
                        </div>
                        <div className="col-xs-12 col-md-8 productDetailsBlock">
                            <h5 className="productTitle">{val.heading}</h5>
                            <hr />
                            <div className="txtStrike">MRP: <span>{Global.CurrencyConverter(val.postpreprice)}</span></div>
                            <div className="txtPrice">Pre-Order: <span>{Global.CurrencyConverter(val.preorderprice)}</span></div>
                            <div className="txtsave">You Save: {Global.digitDiff(val.postpreprice, val.preorderprice)} (	Inclusive of all taxes)</div>
                            {/* <div className="txtsave"> 80.00 delivery: June 18 - 19 Details</div> */}
                            <div className="productLeft">Only 2 left in stock.</div>
                            <hr />
                            <div className="pdetailsection">
                                <div>
                                    <strong>Material</strong>
                                    <span>{val.material}</span>
                                </div>
                                <div>
                                    <strong>Product Height</strong>
                                    <span>{val.productheight}</span>
                                </div>
                                <div>
                                    <strong>Weight</strong>
                                    <span>{val.productweight}</span>
                                </div>
                                <div>
                                    <strong>Date First Available</strong>
                                    <span>{val.datecreated}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="col-xs-12 paymentModeBlock">
                                <p>Cards and NetBanking are on the way!!!. We are on <img src={GPAY} alt="Google Pay" className="gpayIcon" /> - Trusted by everyone.</p>

                                <p>Please use below Steps to make the easy payment</p>
                                <ul>
                                    <li>Scan below barcode or use mobile number for payment.</li>
                                    <li>After payment fill the below form and submit.</li>
                                    <li>After successful Submit, you will receive 14 digit reference number.</li>
                                    <li>Keep that Reference number for tracking. You can do tracking via youe mobile and email as well</li>
                                </ul>
                                {postDataResponse  &&
                                 <div className="alreadyLoggedin">
                                 <Alert variant="success">
                                     <Alert.Heading>{postDataResponse[0].result.message}</Alert.Heading>
                                  
                                     <div className="">
                                          <p>Congratulation!! .</p>
                                         <p>Please use the below reference id as booking reference - <span className="refNo">{postDataResponse[0].result.referenceNo}</span></p>
                                     </div>
                                    </Alert>
                             </div>
                                }
                                <div className="gpayform">
                                    <p>Google Pay Mobile number - <strong>7032918106</strong></p>
                                    <Form onSubmit={submitPayment}>
                                        <Form.Group>
                                            <Form.Label>UPI transaction ID - (<small>last 4 digits</small>)</Form.Label>
                                            <Form.Control type="text" required name="upitransactionId" placeholder="" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Your Name</Form.Label>
                                            <Form.Control type="text" required name="customername" placeholder="" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Your Email</Form.Label>
                                            <Form.Control type="text" required name="customeremail" placeholder="" />
                                            <Form.Text className="text-muted">You will receive receipt on this email.</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Your Mobile</Form.Label>
                                            <Form.Control type="text" required name="customermobile" placeholder="" />
                                            <Form.Text className="text-muted">We will not share your mobile number with anyone.</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="hidden" required name="productid" defaultValue={val.id} />
                                            <Form.Control type="hidden" required name="amount" defaultValue={val.preorderprice} />
                                            {!statusForm && 
                                                 <Button type="submit">Make Payment</Button>
                                            }
                                              {statusForm && 
                                              <div className="submitForm">
                                                   {Global.WaitBlock("Please Wait...We are submitting your form.")}
                                              </div>
                                                }
                                        </Form.Group>
                                    </Form>
                                </div>

                            </div>

                        </div>

                    </div>
                ))}
            </div>
            <JSModal className="imageModal" show={modalShow} onHide={() => setModalShow(false)} imgurl={modalImage}/>
        </div>
    )
}
export default ProductDetail;