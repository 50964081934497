import React from 'react';
import '../components/RecentWork.scss';
import { NavLink } from 'react-router-dom';
// import PremImage from "../assets/images/PremImage.png"


let WhoWeAre = (props) => {
  
    return (
        <div className="container WhoWeAre">
            
          
            <div className={'col-xs-12'}>
            <h3 className="head-title">{props.heading}</h3>
            </div>
            <div className="mainblock col-md-12 p-0">
               
                <div className="content col-md-8 p-0">
                    <p>Premier Artfx Studio was created in 2015 in India by Prem Gupta. He is well known artist in India and known for the comic sketching, super heroes sculpturing and action figure building.</p>
                    <p>Premier Artfx Studio is known for the product quality and finishing, which adhere to customer expectations. </p>
                    <p>Premier Artfx Studio is a center of innovative, combining advanced technology and traditional fine art skills. Organized around workshops led by professionals.</p>
                </div>
                <div className="image col-md-4">
                    <img src={props.Image} alt="" />
                </div>
           
            </div>
            <button className="btn btn-primary float-right redirectBtn"> <NavLink to="/whoweare" ><span >View Profile</span> </NavLink></button>
        </div>
    )
}
export default WhoWeAre;