import Modal from "react-bootstrap/Modal";
import { Button, Image } from "react-bootstrap"
let JSMODAL = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            
        >
            <Modal.Header closeButton>
                {props.heading &&
                    <Modal.Title id="contained-modal-title-vcenter">{props.heading} </Modal.Title>
                }
            </Modal.Header>
           <Modal.Body>
                <Image src={props.imgurl} rounded />
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    )
}
export default JSMODAL;