import React, { useState } from "react";
import "./OurServices.scss";
import Image1 from "../assets/images/heman.jpg";
import Image2 from "../assets/images/nagraj.jpg";
import Image3 from "../assets/images/whoweare.png";
import Global from "./Global";
import axios from "axios";
import { Alert } from "react-bootstrap";
let BaseURL = Global.BaseURL();

let WriteTestimonial = () => {
    // const { value, bind, reset } = useInput('');
    const [getData, setPostArray] = useState([])

    const submitTestimonialForm = (evt) => {
        evt.preventDefault();
        const formData = new FormData(evt.target);
        var object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });

        const article = { data: object, type: "testimonial" };
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        // const Cors = { "mode": "no-cors" }

        axios.post(BaseURL + "postDB.php", article, headers)
            .then(response => {
                setPostArray([response.data]);
                console.log("response", response.data)
            })
            .catch(error => {
                console.log("Error===", error)
            })


        // reset();
    }
    return (
        <div className="container WriteTestimonialBlock">
            <div className={'col-xs-12'}>
                <h3 className="head-title">Write Testimonials</h3>
                <p className="subHeading-title">Please tell us about your experience with our product/services.</p>
            </div>
            <div className="col-xs-12 col-md-4 float-left imageslider">
                <figure>
                    <img className="quoteImg" src={Image1} />
                    <img className="quoteImg" src={Image2} />
                    <img className="quoteImg" src={Image3} />

                </figure>
            </div>
            <div className="col-xs-12 col-md-7 float-right testiminialForm">
                {getData[0] && getData[0].result &&
                    //   <p>Success {getData.result.message}</p>

                    <div className="alreadyLoggedin">
                        <Alert variant="info">
                            <Alert.Heading>{getData[0].result.message}</Alert.Heading>
                            {getData[0].result.status > 0 &&
                                <p>Thanks for sharing your testimonial, we will publish your testimonial after Admin approval.</p>

                            }
                           
                        </Alert>
                    </div>
                }
                <form onSubmit={submitTestimonialForm} method="POST">
                    <div className="form-group" >
                        <label htmlFor="exampleInputEmail1">Name*</label>
                        <input type="text" className="form-control" required id="exampleInputEmail1" name="uname" aria-describedby="emailHelp" placeholder="Enter email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address*</label>
                        <input type="email" className="form-control" required id="exampleInputEmail1" name="uemail" aria-describedby="emailHelp" placeholder="Enter email" />
                        <small id="emailHelp" className="form-text text-muted">We'll use this email for Thank You note.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Mobile</label>
                        <input type="number" className="form-control" id="exampleInputEmail1" name="umobile" aria-describedby="emailHelp" placeholder="Enter email" />
                        <small id="emailHelp" className="form-text text-muted">We'll never share your mobile no. with anyone else.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Testimonial</label>
                        <textarea className="form-control" required id="exampleFormControlTextarea1" name="description" rows="3"></textarea>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" type="submit" >Submit</button>
                    </div>
                </form>
            </div>


        </div>
    )
}
export default WriteTestimonial;