import React, { useEffect, useState } from 'react';
import Banner from "./components/Banner";
// import OurServices from "./components/OurServices";
// import RecentWork from "./components/RecentWork";
import HomePage from './view/Home';
// import MainFooter from './components/Footer';
// import OnScrollCall from "./components/onscroll";
import './App.scss';
import Global from "./components/Global"
let BaseURL = Global.BaseURL();

let getData = (setGoingUp, setRecentWork) => {
  let obj = [];
  let recentWork = [];
  Global.dataCall('bannerImages','', (data) => {
    for (var i = 0; i <= data.bannerImglen; i++) {
      if(data.images[i]){
        obj.push(BaseURL + "banner/home/" + data.images[i]);
      }
    
      // recentWork.push(BaseURL+"recentWork/"+data.recentwork[i]);
    }
    for (var i = 0; i <= 10; i++) {
   
      if(data.recentwork[i]){
        recentWork.push(BaseURL + "recentWork/" + data.recentwork[i]);
      }
    
    }
    if (obj) {
      setGoingUp(obj);
      setRecentWork(recentWork);
      localStorage.setItem("bannerImg", JSON.stringify(obj))

    }

  });
}

function App() {
  const [imagesArray, setGoingUp] = useState(false);
  const [recentWorkArray, setRecentWork] = useState(false);
  useEffect(() => {
    console.log('render!');
    getData(setGoingUp, setRecentWork);

    return () => console.log('unmounting...');
  }, []);

  return (
    <div className="wrapper">
      <Banner figImages={imagesArray} />
      <HomePage rWorkObj={recentWorkArray} />
     
    </div>
  );
}

export default App;
