import React from 'react';
import './RecentWork.scss';
import Global from "../components/Global"
import JSModal from "./Modal"

import { NavLink } from 'react-router-dom';
let count = 0;
let getRandomSize=()=> {
    let arr =[6,6,3,3,2,2,2,2,2,2,2,2];
    count++;
    if(!arr[count]){
        arr[count] = 2;
    }
  
    return arr[count];
 
  }
let RecentWork = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalImage, setModalImage] = React.useState(false);
    let setUseState =(animal)=>{
       
        setModalShow(true)
        setModalImage(animal)
    }
    return (
        <div className="container recentWorkContainer">
            
          
            <div className={'col-xs-12'}>
            <h3 className="head-title">Recent Work</h3>
            </div>
            <div col={12} className="imageBlock">
            {!props.recentWorkImages &&
                Global.WaitBlock("Please wait. We are fetching latest updates for you.")
            }
            {props.recentWorkImages && props.recentWorkImages.map((val,index) => (
                <div key={index} className={"recentWorkBlock col-xs-12 col-md-"+getRandomSize()} >
                    <img onClick={() => setUseState(val)} src={val} className="img-responsive" alt="img" />
                 </div>
            ))}

           
            </div>
            <button className="btn btn-primary redirectBtn"> 
            <NavLink to="/portfolio" ><span >View Portfolio</span> </NavLink></button>
            <JSModal className="imageModal" show={modalShow} onHide={() => setModalShow(false)} imgurl={modalImage}/>
        </div>
    )
}
export default RecentWork;