import "../components/OurServices.scss";
import Banner from "../components/Banner"
import React, { useState } from "react";
import Global from "../components/Global";
import axios from "axios";
import { Alert } from "react-bootstrap";
let BaseURL = Global.BaseURL();

let ContactUs = () => {
    const [getData, setPostArray] = useState([])

    const submitContactusForm = (evt) => {
        evt.preventDefault();
        const formData = new FormData(evt.target);
        var object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });

        const article = { data: object, type: "contactus" };
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        // const Cors = { "mode": "no-cors" }

        axios.post(BaseURL + "postDB.php", article, headers)
            .then(response => {
                setPostArray([response.data]);
                console.log("response", response.data)
            })
            .catch(error => {
                console.log("Error===", error)
            })


        // reset();
    }
    return (
        <div className="TrackDelivery no-slider">
            <Banner />
            <div className="container">


                <div className={'col-xs-12'}>
                    <h3 className="head-title">Contact us</h3>
                </div>
                <section className="container ContactusBlock mb-5">
                    <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
        a matter of hours to help you.</p>
                    {getData[0] && getData[0].result &&
                        //   <p>Success {getData.result.message}</p>

                        <div className="alreadyLoggedin">
                            <Alert variant="success">
                                <Alert.Heading>{getData[0].result.message}</Alert.Heading>
                                {getData[0].result.status > 0 &&
                                    <p>Thanks for contacting us, we will revert back within 24hrs.</p>

                                }

                            </Alert>
                        </div>
                    }


                    <div className="row">


                        <div className="col-md-9 mb-md-0 mb-5">
                            <form id="contact-form" name="contact-form" onSubmit={submitContactusForm}>


                                <div className="row">


                                    <div className="col-md-6 ">
                                        <div className="md-form mb-0">
                                            <label htmlFor="name" className="">Your name</label>
                                            <input type="text" id="name" name="uname" className="form-control" />

                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <div className="md-form mb-0">
                                            <label htmlFor="email" className="">Your email</label>
                                            <input type="text" id="email" name="uemail" className="form-control" />

                                        </div>
                                    </div>


                                </div>



                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="md-form mb-0">
                                            <label htmlFor="subject" className="">Subject</label>
                                            <input type="text" id="subject" name="subject" className="form-control" />

                                        </div>
                                    </div>
                                </div>



                                <div className="row mt-2">


                                    <div className="col-md-12">

                                        <div className="md-form">
                                            <label htmlFor="message">Your message</label>
                                            <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea"></textarea>

                                        </div>

                                    </div>
                                </div>
                                <div className="text-center text-md-left mt-4">
                                    <button type="submit" className="btn btn-primary" >Send</button>
                                </div>

                            </form>


                            <div className="status"></div>
                        </div>

                        <div className="col-md-3 text-center">
                            <ul className="list-unstyled mb-0">
                                <li><i className="fas fa-map-marker-alt fa-2x"></i>
                                    <p>New Delhi, India</p>
                                </li>

                                <li><i className="fas fa-phone mt-4 fa-2x"></i>
                                    <p>+91 123456789</p>
                                </li>

                                <li><i className="fas fa-envelope mt-4 fa-2x"></i>
                                    <p>Premier@artfx.com</p>
                                </li>
                            </ul>
                        </div>


                    </div>

                </section>
            </div>
        </div>
    )
}
export default ContactUs;