import Banner from "../components/Banner"
import { Form, Button, Alert } from "react-bootstrap";
import React, { useState } from 'react';
import axios from "axios";
import Global from "../components/Global";
let BaseURL = Global.BaseURL();

let TrackDelivery = () => {
    const [statusForm, submitForm] = useState(false);
    const [getData, postData] = useState(false);
    const [emptyForm, isEmptyForm] = useState(false);

    const trackDelivery = (evt) => {

        submitForm(true);
        isEmptyForm(false);
        evt.preventDefault();
        const formData = new FormData(evt.target);
        var object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });
        // console.log(object['customermobile'], "-----------", object['referencenumber']);
        if (object['customermobile'] === '' && object['referencenumber'] === '') {
            isEmptyForm(true);
            submitForm(false);
            return false;
        }
        const article = { data: object, type: "trackbooking" };
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        // const Cors = { "mode": "no-cors" }

        axios.post(BaseURL + "GetDB.php", article, headers)
            .then(response => {
                submitForm(false)
                if (response && response.data) {
                    console.log(response.data)
                    postData(response.data);
                }


            })
            .catch(error => {
                submitForm(false)
                console.log("Error===", error)
            })

    }
    return (
        <div className="TrackDelivery no-slider">
            <Banner />
            <div className="container">

                <div className={'col-xs-12'}>
                    <h3 className="head-title">Track Your Delivery</h3>
                </div>
                <div className="deliveryForm col-md-12 p-0">
                    {emptyForm &&
                        <div className="NoResult">
                            <Alert variant="danger">
                                <p>Ohooo...Please provide either mobile number or reference id to proceed further.</p>
                            </Alert>
                        </div>
                    }
                    <Form onSubmit={trackDelivery}>
                        <div className="col-md-3 float-left mt-4">

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                </div>
                                <Form.Control type="number" className="form-control" name="customermobile" placeholder="Mobile Number" />
                                <div className="invalid-feedback">
                                    Please choose a username.
                        </div>
                            </div>
                        </div>
                        <div className="col-md-1 text-center float-left mt-4 ">
                            <p>OR</p>
                        </div>
                        <div className="col-md-3 float-left mt-4 ">

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="tnumber">@</span>
                                </div>
                                <Form.Control type="number" className="form-control" name="referencenumber" placeholder="Tracking Number" />
                                {/* <input type="number" className="form-control" id="trackNumber" placeholder="Tracking Number" aria-describedby="tnumber" /> */}
                                <div className="invalid-feedback">
                                    Please choose a username.
                        </div>
                            </div>
                        </div>
                        <div className="col-md-3 float-left ">
                            {!statusForm &&
                                <Button className="btn btn-primary  mt-4" type="submit">Search</Button>
                            }
                            {statusForm &&
                                <div className="submitForm">
                                    {Global.WaitBlock("Please Wait...We are submitting your form.")}
                                </div>
                            }
                        </div>
                    </Form>
                </div>
                <div className="trackResultBlock container">

                    {getData && getData.result &&

                        <div className="bookingdetails">
                            <h5>Your Order Details:</h5>
                            {!getData.result.userData &&
                                <div className="NoResult">
                                    <Alert variant="danger">
                                        <p>No Result Found.</p>
                                    </Alert>
                                </div>
                            }

                            {getData.result.userData && getData.result.userData.map((val) => (
                                <div className="orderDetailsBlock" key={val.id}>
                                    <div className="col-5 p-0 float-left orderImage">
                                        <img src={BaseURL + "shop/" + val.productid + ".jpg"} alt="" />
                                    </div>
                                    <div className="col-7 p-0 float-left orderDetails">
                                        <p className="txtAmount">Amount: <span>{Global.CurrencyConverter(val.amount)}</span></p>
                                        <p className="txtOrderDate">OrderDate: <span>{Global.changeDateFormat(val.orderdate)}</span></p>
                                        <p className="txtReference">Reference No: <span>{val.referencenumber}</span></p>
                                        <p className="txttId">UPI ID: <span>{val.upitransactionId}</span></p>
                                        <p className="txtdStatus">Delivery Status: <span>{val.deliverystatus}</span></p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default TrackDelivery;