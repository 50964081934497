import React, { useEffect, useState } from 'react';
import './Banner.scss';
import WhiteLogo from "../assets/images/logo2.png";
import { NavLink } from 'react-router-dom';
import JSModal from "./Modal"
let Banner = (props) => {
    const [navShow, setNav] = useState(0);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalImage, setModalImage] = React.useState(false);

    useEffect(() => {
        window.onscroll = () => {
            setNav(window.pageYOffset)
        }
    }, []);

    let setUseState =(animal)=>{
       
        setModalShow(true)
        setModalImage(animal)
    }
    let getImage = (props && props.figImages) ? props.figImages : JSON.parse(localStorage.getItem("bannerImg"));
    const showSticky = (navShow > 250) ? 'displayNav' : "d-none";
    return (

        <div className="container-fluid m-0 p-0 topBannerContainer">
            <div className="bannerBlock">
                <div className="overlay-bg"></div>

                <nav className={"navbar navbar-expand-lg navbar-dark bg-dark sticky-top " + showSticky}>
                    <NavLink to="/home" className="navbar-brand float-left">

                        <h1 className="logo">
                            <img src={WhiteLogo} className="img-responsive logoImg float-left" alt="img" />
                            <div className="logoTextBlock float-left">
                                <div className="text-top">
                                    <div className="text1">PREMier ARTFX <span className="txtstudio">STUDIO</span> </div>


                                </div>

                            </div>
                        </h1>
                    </NavLink>

                    <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse float-right" id="navbarText">
                        <div className="navbar-text">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item ">
                                    <NavLink to="/home" className="nav-link" ><span >Home</span> </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/portfolio" className="nav-link" ><span >Portfolio</span> </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/profile" className="nav-link" ><span >Profile</span> </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/shop" className="nav-link" ><span >Shop</span> </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/trackdelivery" className="nav-link" ><span >Track Delivery</span> </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/contactus" className="nav-link" ><span >Contact Us</span> </NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
                <header>
                    <div className="container">
                        <div className="col-md-12">
                            <h1 className="logo">
                                <img src={WhiteLogo} className="img-responsive logoImg float-left" alt="img" />
                                <div className="logoTextBlock float-left">
                                    <div className="text-top">
                                        <span className="text1">PREMier</span>
                                        <span className="text2">FX</span>
                                    </div>
                                    <div className="text-bottom">
                                        <span className="text3">STUDIO</span>
                                        <span className="text4">ART</span>
                                    </div>
                                </div>
                            </h1>


                            <div id="cssmenu">
                                <ul>
                                    <li className="has-sub">
                                        <NavLink to="/home"  ><span >Home</span> </NavLink>
                                    </li>
                                    <li className="has-sub">
                                        <NavLink to="/portfolio" ><span >Portfolio</span> </NavLink>
                                    </li>
                                    <li className="has-sub">
                                        <NavLink to="/profile" ><span >Profile</span> </NavLink>

                                    </li>
                                    <li className="has-sub">
                                        <NavLink to="/shop" ><span >Shop</span> </NavLink>
                                    </li>

                                    <li className="has-sub">
                                        <NavLink to="/trackdelivery" ><span >Track Delivery</span> </NavLink>

                                    </li>
                                    <li className="has-sub">
                                        <NavLink to="/contactus" ><span >Contact Us</span> </NavLink>

                                    </li>
                                    {/* <li className="last"><a href="./contact.html"><span>Contact Us</span></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="slider-content container">
                    <div id="carousel">
                        {getImage && getImage.slice(0, 5).map((animal, index) => (
                            <span key={index} className="cimage"><img onClick={() => setUseState(animal)} src={animal} alt="img" /></span>
                        ))}


                    </div>

                </div>




                <div className="container welcome d-none">
                    <NavLink to="/shop" className="shopnowBtn" >Shop Now !!! </NavLink>


                </div>

            </div>
            <JSModal className="imageModal" show={modalShow} onHide={() => setModalShow(false)} imgurl={modalImage}/>
        </div>
    )

}
export default Banner;