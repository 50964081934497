import { ModalFooter } from "react-bootstrap"

let MainFooter = (props) => {
    return(
        <div className="footerBlock text-center">
            <div className="fb-share-button d-none" 
data-href="http://premierartfxstudio.dollardreamsvillas.com/#/productdetails/2" 
data-layout="button_count">
</div>
            <p className="cwPara">&copy; Premier ArtsFX Studio 2021</p>
            <p>Developed by - mshukla Codes</p>
        </div>
    )
}
export default MainFooter;