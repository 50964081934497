import OurServices from "../components/OurServices";
import RecentWork from "../components/RecentWork";
import WhoWeAre from "../components/WhoWeAre";
import Testimonial from "../components/Testimonial"; 
import WriteTestimonial from "../components/WriteTestimonial"; 

import WhoWeAreImg from "../assets/images/whoweare.png"
let Home =(props)=>{
    return(
       <div className="homeContainer container mb-5">
        <OurServices />
        <WhoWeAre heading="Who We Are" Image={WhoWeAreImg} />
		<RecentWork recentWorkImages={props.rWorkObj} />
        <Testimonial />
        <WriteTestimonial />
       
       </div>
    )
}
export default Home;