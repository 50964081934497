import "./OurServices.scss";
import FBUser from "../assets/images/fbuser.png";
import FBUser2 from "../assets/images/fbuser2.png";

let Testimonial = () => {
    return (
        <div className="container TestimonialBlock">
              <div className={'col-xs-12'}>
            <h3 className="head-title">Testimonials</h3>
            </div>
            <div className="col-xs-12 col-md-6 testimonialColumn">
                <img className="quoteImg" src={FBUser2} />
                <div className=" quotesBlock">
                &quot; Great Work , happy, some is doing great stuff from india &quot;  - <br /><strong> Kanav Agnihotri(FB User)</strong>
                </div>
            </div>
            <div className="col-xs-12 col-md-6 testimonialColumn">
                <img className="quoteImg" src={FBUser} />
                <div className=" quotesBlock">
                &quot; Great Work by Prem &quot;  - <br /><strong> Manish Shukla(FB User)</strong>
                </div>
            </div>
          
        </div>
    )
}
export default Testimonial;