// import { store } from './app/store';
// import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/js/bootstrap";
import './App.scss';

// import reportWebVitals from './reportWebVitals';
import {Routes} from './Router.js';
import { HashRouter } from 'react-router-dom'
import Store from "./store";
// import swDev from "./swDev";

ReactDOM.render(
  <HashRouter store={Store}>
      <Routes/>
  </HashRouter> ,
  document.getElementById('root')
);
// swDev();

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
