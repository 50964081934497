import Banner from "../components/Banner";
import React, { useEffect, useState } from 'react';
import Global from "../components/Global"
import WhoWeAre from "../components/WhoWeAre";
import WhoWeAreImg from "../assets/images/PremImage.png"
import logoRJ from "../assets/images/brandicons/Raj_Comics_logo.jpg";
import logodl from "../assets/images/brandicons/dicetoylab.png";
import logofc from "../assets/images/brandicons/fenilcomic.png";
import logol10 from "../assets/images/brandicons/Level10comicslogo.jpg";
import logoVC from "../assets/images/brandicons/vimanika.jpg";
import logoBJ from "../assets/images/brandicons/bj.png";
import logoAS from "../assets/images/brandicons/angrysquad.jpg";
import logoKC from "../assets/images/brandicons/killercomics.jpg";

let BaseURL = Global.BaseURL();
let getData = (setGoingUp, setbanner) => {
    let obj = [];
    let bannerObj = [];

    Global.dataCall('portfolio','',(data) => {
        for (var i = 0; i <= data.len; i++) {
            if (data.images[i]) {
                obj.push(BaseURL + "portfolio/" + data.images[i]);
            }
            if (data.banner[i]) {
                bannerObj.push(BaseURL + "banner/portfolio/" + data.banner[i])
            }

        }
        if (obj) {
            setGoingUp(obj);
        }
        if (bannerObj) {
            setbanner(bannerObj);
        }
    });
}
let Profile = () => {
    const [imagesArray, setGoingUp] = useState(false);
    const [bannerArray, setbanner] = useState(false);
    useEffect(() => {
        console.log('render!');
        getData(setGoingUp, setbanner);
        return () => console.log('unmounting...');
    }, []);
    return (
        <div className="Profile no-slider mb-5">
            <Banner />
            <div className="container homeContainer">


                <div className={'container'}>
                    <WhoWeAre heading="Profile" Image={WhoWeAreImg} />
                </div>
                <div className={'container'}>
                    <h3 className="head-title">Our Experiences With</h3>

                    <div className={'col-xs-12'}>
                        <div className="brandlist">
                            <div className="brandIcon">
                                <img src={logoRJ} alt="" />
                            </div>
                            <p>Raj comic 2015</p></div>
                        <div className="brandlist">
                            <div className="brandIcon"><img src={logofc} alt="" /></div>
                            <p>Fenil comic 2015</p></div>
                        <div className="brandlist">
                            <div className="brandIcon"> <img src={logol10} alt="" /></div>
                            <p>Level 10 comics 2016</p></div>
                        <div className="brandlist">
                            <div className="brandIcon"><img src={logodl} alt="" /></div>
                            <p>Dice toy lab 2018</p>
                        </div>
                        <div className="brandlist">
                            <div className="brandIcon"><img src={logoVC} alt="" /></div>
                            <p>Vikmanika comic 2018 to 2019.</p>
                        </div>
                        <div className="brandlist">
                            <div className="brandIcon"> <img src={logoBJ} alt="" /></div>
                            <p>Bhavesh joshi superhero toy (work for Vikramaditya motwane) 2019</p>
                        </div>

                    </div>
                </div>
                <div className={'container'}>
                    <h3 className="head-title">International Project</h3>

                    <div className={'col-xs-12'}>
                        <div className="brandlist">
                            <div className="brandIcon"> <img src={logoAS} alt="" /></div>
                            <p>Angry Fred squad comics - 2021</p>
                        </div>
                        <div className="brandlist">
                            <div className="brandIcon"> <img src={logoBJ} alt="" /></div>
                            <p>Ninja universe (web series) 2018 to 2019</p>
                        </div>
                        <div className="brandlist">
                            <div className="brandIcon"> <img src={logoKC} alt="" /></div>
                            <p>killer comics (executioner crucifix) 2017</p>
                        </div>
                    </div>
                </div>
            </div >
        </div >



    )
}
export default Profile;